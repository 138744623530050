<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <div class="image-container">
                <div class="image-company">
                  <img :src="showImage || company.url_Logo" alt=""/>
                  <div class="image-button">
                    <div class="btn-img">
                      <i class="fas fa-camera"></i>
                      <input
                          @change="PreviewImage"
                          class="file-input"
                          type="file"
                          name="resume"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                  dense
                  outlined
                  :label="$t('Company.table.name')"
                  v-model="company.name"
                  :hint="`${server_errors.name}`"
                  @keydown.enter="focusNext"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                  @keydown.enter="focusNext"
                  class="input-number"
                  type="number"
                  dense
                  outlined
                  :label="$t('Company.table.phone')"
                  v-model="company.phone"
                  :hint="`${server_errors.phone}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  @keydown.enter="focusNext"
                  dense
                  outlined
                  :label="$t('Company.table.email')"
                  v-model="company.email"
                  :hint="`${server_errors.email}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                  @keydown.enter="focusNext"
                  dense
                  outlined
                  :label="$t('Company.table.address')"
                  v-model="company.address"
                  :hint="`${server_errors.address}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-select
                  @keydown.enter="focusNext"
                  dense
                  outlined
                  :label="$t('Company.table.businessType')"
                  :items="businessType"
                  item-text="name"
                  item-value="id"
                  v-model="company.type_business_id"
                  :hint="`${server_errors.type_business_id}`"
                  persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="6" md="6">
              <DatePicker
                  class="date-time"
                  @keydown.enter="focusNext"
                  style="width: 100%"
                  type="date"
                  v-model="company.founding_date"
                  valueType="format"
                  slot="activator"
                  :append-to-body="true"
                  name="founding_date"
                  :placeholder="$t('Company.table.foundingDate')"
              ></DatePicker>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <label class="label-input">{{ $t("Company.vat") }}</label>
              <v-radio-group row v-model="company.charge_vat">
                <v-radio
                    class="label-input"
                    value="yes"
                    :label="$t('Company.vat_yes')"
                >
                </v-radio>
                <v-radio
                    class="label-input"
                    value="no"
                    :label="$t('Company.vat_no')"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <label class="label-input">{{ $t("Company.clock_action.title") }}</label>
              <v-radio-group row v-model="company.edit_clock_in_out">
                <v-radio
                  class="label-input"
                  value="yes"
                  :label="$t('Company.clock_action.yes')"
                >
                </v-radio>
                <v-radio
                  class="label-input"
                  value="no"
                  :label="$t('Company.clock_action.no')"
                >
                </v-radio>
              </v-radio-group>
            </v-col>


            <!--            <v-col cols="12" md="12" lg="12">-->
            <!--              <label class="label-input">{{ $t("Company.tax") }}</label>-->
            <!--              <v-radio-group row v-model="company.include_salary_tax">-->
            <!--                <v-radio class="label-input" value="yes" :label="$t('Company.tax_yes')">-->

            <!--                </v-radio>-->
            <!--                <v-radio class="label-input" value="no" :label="$t('Company.tax_no')">-->

            <!--                </v-radio>-->
            <!--              </v-radio-group>-->
            <!--            </v-col>-->
            <v-col cols="6">
              <v-btn
                  class="btn-save-change"
                  @click="validated()"
                  :loading="btnLoading"
              >
                {{ $t("Company.action.saveChange") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      btnLoading: false,
      showImage: "",
      imageFile: null,
      businessType: [],
      nameRules: [(v) => !!v || "field is required..."],
      data: {
        logo: "",
      },
      server_errors: {
        name: "",
        phone: "",
        email: "",
        address: "",
        type_business_id: "",
        founding_date: "",
        logo: "",
      },
    };
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    fetchTypeOfBusiness() {
      this.$axios.get(`admin/type-business`).then((res) => {
        this.businessType = res.data.data;
      });
    },

    PreviewImage: function (event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },

    validated() {
      if (this.$refs.form.validate()) {
        this.UpdateCompany();
        this.btnLoading = true;
      }
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios
          .post(`upload-file`, formData)
          .then((res) => {
            if (res.status === 200) {
              this.data.logo = res.data.filename;
            }
          })
          .catch(() => {
          });
    },

    UpdateCompany() {
      const formData = new FormData();
      formData.append("name", this.company.name);
      formData.append("phone", this.company.phone);
      formData.append("email", this.company.email);
      formData.append("address", this.company.address);
      formData.append("type_business_id", this.company.type_business_id);
      formData.append("founding_date", this.company.founding_date);
      formData.append("newLogo", this.data.logo);
      formData.append("charge_vat", this.company.charge_vat);
      formData.append("include_salary_tax", 'no');
      formData.append("edit_clock_in_out", this.company.edit_clock_in_out)
      formData.append('_method', 'PUT');
      this.$axios.post(`admin/company/${this.company.id}`, formData).then((res) => {
        this.btnLoading = true;
        if (res.data.success === true) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
          this.$router.push({name: "company.index"})
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
          this.btnLoading = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      company: "Company/getCompany",
    }),
    // company() {
    //   return this.$store.getters["Company/getCompany"];
    // },
  },
  created() {
    this.fetchTypeOfBusiness();
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;
}

.from-content {
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: center;

  .from {
    width: 600px;
    height: auto;
  }
}
</style>
